import React from 'react'

import crs from '../../assests/images/partners/crs.png'
import homeserve from '../../assests/images/partners/homeserve-removebg-preview.png'
// import mlspin from '../../assests/images/partners/mlspin.png'
import realtyna from '../../assests/images/partners/realtyna.png'
// import smartmls from '../../assests/images/partners/smartmls.png'
import stripe from '../../assests/images/partners/stripe.png'
import { useTranslation } from 'react-i18next'


const Partners = () => {
    const { t } = useTranslation()
    const featureImage = [
        { img: crs },
        { img: homeserve },
        // { img: mlspin },
        { img: realtyna },
        // { img: smartmls },
        { img: stripe },
    ]
    return (
        <>
            <div className='py-10 bg-gray-100'>
                <div className='container mx-auto'>
                    <h2 className='text-center text-[2.5rem] font-bold p-0'>{t('partners')}</h2>
                    <div className="grid lg:grid-cols-4 grid-cols-2 gap-5 items-center mt-4">
                        {
                            featureImage.map((list, i) => (
                                <div className='p-5' key={i}>
                                    <img src={list.img} className="object-cover w-[100%] grayscale hover:grayscale-0 transition duration-300" alt="feature_image" />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

        </>
    )
}

export default Partners